import { PhoneNumber } from 'libphonenumber-js/core';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import { type Profile } from '../../../../services/src/lib/member/userProfile';
import {
    HealthOptionIds,
    type BillingAddressFields,
    type CustomerAddress,
} from '@tgg/common-types';

export const mapHealthData = (healthResponse?: boolean) => {
    let mappedResponse = '';
    if (typeof healthResponse !== 'undefined') {
        mappedResponse = healthResponse
            ? 'health-question-yes'
            : 'health-question-no';
    }
    return mappedResponse as HealthOptionIds | '';
};

export const prepopulatePhoneNumber = (number: string) => {
    const isNumber = isValidPhoneNumber(number);
    const parsed = parsePhoneNumber(number);

    if (isNumber && parsed) {
        return parsed?.country === 'GB'
            ? (parsePhoneNumber(number, 'GB') as PhoneNumber).number
            : number;
    }
    if (isValidPhoneNumber(number, 'GB')) {
        return (parsePhoneNumber(number, 'GB') as PhoneNumber).number;
    }

    return '';
};

export const normaliseProfileApiResponse = (
    profileResponse: Partial<Profile>,
) => ({
    firstName: profileResponse.firstName ?? '',
    lastName: profileResponse.lastName ?? '',
    dob: profileResponse.dateOfBirth ?? '',
    email: profileResponse.email || '',
    health: mapHealthData(profileResponse.isDisabledAccessRequired),
    phoneNumber: prepopulatePhoneNumber(profileResponse.mobilePhone ?? ''),
    maleFemale: profileResponse.gender ?? '',
    addressLine1: profileResponse.addressLine1 || '',
    addressLine2: profileResponse.addressLine2 || '',
    addressLine3: profileResponse.addressLine3 || '',
    cityTown: profileResponse.townOrCity || '',
    postcode: profileResponse.postcode || '',
    commsPreferenceSms: profileResponse.commsPreferenceSms ?? false,
    commsPreferenceEmail: profileResponse.commsPreferenceEmail ?? false,
});

export const normaliseProfileApiResponseWithAccountData = (
    profileResponse: Profile,
) => ({
    ...normaliseProfileApiResponse(profileResponse),
    accountDetailPrompt: profileResponse.accountDetailPrompt ?? null,
});

export const mapBillingAddressFromCustomerAddress = (
    customerAddress?: CustomerAddress,
): BillingAddressFields => ({
    addressLine1: customerAddress?.addressLine1 ?? '',
    addressLine2: customerAddress?.addressLine2 ?? '',
    addressLine3: customerAddress?.addressLine3 ?? '',
    city: customerAddress?.city ?? '',
    country: 'GB',
    postCode: customerAddress?.postCode ?? '',
    state: '',
});

export const mapBillingAddressFromProfile = (
    profile: Partial<Profile>,
): BillingAddressFields => ({
    addressLine1: profile.addressLine1 ?? '',
    addressLine2: profile.addressLine2 ?? '',
    addressLine3: profile.addressLine3 ?? '',
    city: profile.townOrCity ?? '',
    postCode: profile.postcode ?? '',
    country: 'GB',
    state: '',
});
