import { AccountData } from '@tgg/common-types';

export interface DirectDebitInstructionOverlayProperties {
    /**
     * AccountData - Dynamic, as returned from the payments/paymentReference call
     */
    accountData: AccountData;
    /**
     * Reference - Dynamic, as returned from the payments/paymentReference call
     */
    paymentReference: string;

    /**
     * The text displayed within the link to open the overlay
     */
    linkText?: string;

    /**
     * If true, the overlay will be displayed on render
     */
    defaultIsOpen?: boolean;

    /**
     * Differentiates types based on the app (commerce vs member) and displays the elements accordingly
     */
    overlayType?: OverlayType;
}

export enum OverlayType {
    /**
     * Commerce application
     */
    COMMERCE = 'COMMERCE',

    /**
     * Member application
     */
    MEMBER = 'MEMBER',
}
