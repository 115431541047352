import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { HorizontalRule } from '../HorizontalRule';

export const StyledLink = styled('a')`
    color: ${palette.primary.dark};
    font-weight: bold;
    text-decoration: none;
    & :hover {
        color: ${palette.primary.dark};
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 3rem 0;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 6rem 0;
    }
`;

export const StyledAlertMessage = styled(AlertMessage)`
    margin-bottom: 2rem;
`;
