import { ErrorCodes } from './error-codes.types';

export interface DirectDebitDetails {
    accountId: string;
    accountNumber: string;
    sortCode: string;
    accountHolderName: string;
    authorizedCardHolderAgreementCapture: boolean;
    authorizedCardHolderAgreementCaptureDate: string;
}

export interface BillingAddressFields {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city: string;
    country: string;
    postCode: string;
    state?: string;
}
export interface CustomerAddress {
    addressLine1: string | null;
    addressLine2?: string | null;
    addressLine3?: string | null;
    city: string | null;
    postCode: string | null;
}

export enum PaymentMethod {
    Paypal = 'Paypal',
    Card = 'Card',
}

export interface CookieCardDetails {
    last4Digits: string;
    expiryDate: string;
}

export interface AccountData {
    /**
     * Name(s) of Account Holder(s) - dynamic, should be in the cookie
     */
    accountName: string;

    /**
     * Bank / Building Society Account Number - dynamic, should be in the cookie
     */
    bankAccountNumber: string;

    /**
     * Bank account sort code?
     */
    bankAccountSortCode: string;

    /**
     * Name and Address of your Bank or Building Society - Dynamic.
     * TBC. Should be in response from the payments/validateBankAccount, but might not be.
     */
    bankName: string;

    /**
     * Bank address
     */
    bankAddress: string[];

    /**
     * User's next billing date in member's app
     */
    nextBillingDate?: string;
}

export type PaymentErrorConstructorOptions = {
    originalError?: Error;
};

export type PaymentErrorOptions = {
    redirect?: boolean;
    originalError?: unknown;
};
export class PaymentError extends Error {
    code: ErrorCodes;

    originalError?: unknown;

    redirect?: boolean;

    constructor(
        message: string,
        code: ErrorCodes,
        options?: PaymentErrorOptions,
    ) {
        super(message);
        this.code = code;
        this.originalError = options?.originalError;
        this.redirect = options?.redirect;
    }
}

export interface CardPaymentInfo {
    cardNumber: string;
    expiryDate: string;
    nameOnCard: string;
    securityCode: string;
}
